// import external dependencies
import 'jquery';
// import 'masonry-layout/dist/masonry.pkgd.min';

import './vendor/jssocials.custom.js';
import './vendor/jquery.range.js';
import './site-header.js';
// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import {
  faFacebookF,
  faTwitter,
  faPinterestP,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import {
  faShoppingBasket,
  faUserCircle,
  faDownload,
  faIndustry,
  faMotorcycle,
  faCarSide,
  faShuttleVan,
  faMapMarkerAlt,
  faQuestion,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
// Import everything from autoload
import './autoload/_bootstrap.js';
// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import singlePost from './routes/singlePost';
import ouNousTrouver from './routes/ouNousTrouver';
import singleProduct from './routes/singleProduct';
import blog from './routes/blog';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,

  // Home page
  home,

  // About Us page, note the change from about-us to aboutUs.
  aboutUs,

  // Page single post
  singlePost,

  // Page contact
  ouNousTrouver,

  // Page produit, décommenter route & import pour réactiver l'états des stocks
  singleProduct,

  blog,
});

// add the imported icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faPinterestP,
  faLinkedinIn,
  faShoppingBasket,
  faUserCircle,
  faDownload,
  faIndustry,
  faCarSide,
  faMotorcycle,
  faShuttleVan,
  faMapMarkerAlt,
  faQuestion,
  faPhone
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

$(document).ready(function () {
  routes.loadEvents();
});
