jQuery(function($) {
  $(".menu-toggle").on("click", function() {
    if ($(this).hasClass("activated")) {
      $("html").addClass("overflow-hidden");
    } else {
      $("html").removeClass("overflow-hidden");
    }
  });
  $(window).resize(function() {
    if (window.innerWidth >= 992) {
      $("html").removeClass("overflow-hidden");
    }
  });
});
