import "../vendor/jssocials.custom";
export default {
    init() {
        // scripts here run on the DOM load event
        $("#share").jsSocials(
            {
                showLabel: false,
                showCount: false,
                shares: ["facebook", "twitter", "linkedin"],
            }
        );
    },
    finalize() {
        // scripts here fire after init() runs
    },
};
