import autosize from 'autosize/dist/autosize.min.js';
import initMenus from '../vendor/responsive-menu.js';

export default {
  init() {
    initMenus();

    //submit button loading animation
    $('.wpcf7-submit').on('click', function () {
      $(this).addClass('loading');
    });
    $('.wpcf7-submit').on('click', function () {
      $(this).addClass('loading');
    });

    $('.wpcf7').on(
      'wpcf7invalid wpcf7spam wpcf7mailsent wpcf7mailfailed',
      function () {
        $(this).find('.wpcf7-submit').removeClass('loading');
      }
    );
    // minimalist autoresizable textareas
    autosize($('textarea'));
    $('#share').jsSocials({
      showLabel: false,
      showCount: false,
      shareIn: 'popup',
      shares: ['facebook', 'twitter', 'linkedin', 'pinterest'],
    });

    $('.input-file').each(function () {
      var $input = $(this),
        $label = $('.js-labelFile'),
        labelVal = $label.html();

      $input.on('change', function (element) {
        var fileName = '';
        if (element.target.value)
          fileName = element.target.value.split('\\').pop();
        fileName
          ? $label.addClass('has-file').find('.js-fileName').html(fileName)
          : $label.removeClass('has-file').html(labelVal);
      });
    });

    $('li.panier-shop').hover(
      function () {
        $(this)
          .find('.widget_shopping_cart')
          .stop(true, true)
          .delay(200)
          .fadeIn(500);
      },
      function () {
        $(this)
          .find('.widget_shopping_cart')
          .stop(true, true)
          .delay(200)
          .fadeOut(500);
      }
    );
    $(window).load(function () {
      $('.range-slider').each(function () {
        $(this).jRange({
          from: $(this).attr('data-min'),
          to: $(this).attr('data-max'),
          scale: $(this).attr('data-scale'),
          step: 1,
          format: '%s',
          width: 200,
          showLabels: true,
          isRange: true,
        });
      });
    });
    const idsInput = [
      '#billing_first_name_field',
      '#billing_last_name_field',
      '#billing_country_field',
      '#billing_company_field',
      '#billing_address_1_field',
      '#billing_address_2_field',
      '#billing_postcode_field',
      '#billing_city_field',
      '#billing_phone_field',
      '#billing_email_field',
      '#shipping_first_name_field',
      '#shipping_last_name_field',
      '#shipping_country_field',
      '#shipping_company_field',
      '#shipping_address_1_field',
      '#shipping_address_2_field',
      '#shipping_postcode_field',
      '#shipping_city_field',
      '#order_comments_field',
    ];
    $('.woocommerce-address-fields__field-wrapper').addClass('row');

    idsInput.map((elt) => {
      $(elt).removeClass('form-row');
      $(elt).addClass('col-12');
    });
    let add_to_cart_button = document.querySelectorAll('a.add_to_cart_button');
    for (let i = 0; i < add_to_cart_button.length; i++) {
      add_to_cart_button[i].addEventListener('click', function () {
        let svg_animation =
          "<svg version='1.1' id='L6' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='width: 30px'  x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve'><rect fill='none' stroke='#fff' stroke-width='4' x='25' y='25' width='50' height='50'><animateTransform attributeName='transform' dur='0.5s' from='0 50 50' to='180 50 50' type='rotate' id='strokeBox' attributeType='XML' begin='rectBox.end'/></rect><rect x='27' y='27' fill='#fff' width='46' height='50'><animate attributeName='height' dur='1.3s' attributeType='XML' from='50' to='0' id='rectBox' fill='freeze' begin='0s;strokeBox.end'/></rect></svg>";
        add_to_cart_button[i].innerHTML = svg_animation;
      });
    }
    if ($('.cart-items-count').text() == 0) {
      $('.cart-items-count').addClass('d-none');
    }
    let menuResponsive = document.getElementById('menu-responsive-bottom');
    let newElt = document.createElement('button');
    newElt.classList.add(
      'menu-item',
      'menu-',
      'border-0',
      'bg-transparent',
      'mt-auto',
      'mb-auto'
    );
    newElt.setAttribute('type', 'button');
    newElt.setAttribute('data-toggle', 'modal');
    newElt.setAttribute('data-target', '#phoneNumber');
    newElt.innerHTML = "<i class='fas fa-phone text-white icon-menu'></i>";
    menuResponsive.insertBefore(newElt, menuResponsive.firstChild.nextSibling);
    $('#myTab [data-toggle]').first().tab('show');

    $('#BestSellings [data-toggle]').first().tab('show');
    if (document.getElementById('numberplate')) {
      let submitNumberplate = document.getElementById('numberplate');
      submitNumberplate.addEventListener('click', function (e) {
        e.preventDefault();
        let input = $('#plaqueValue');
        let inputValue = input.val();
        $('#battery-search-title').html(
          'Recherche de pièces Varta recommendées pour votre véhicule...'
        );
        $('#battery-search-body').html('<div class="pulse-spinner p-5"></div>');
        let xmlRequest = $.ajax({
          url: ajaxurl,
          method: 'POST',
          data: {
            action: 'load_res_varta',
            plaque: inputValue, // FG124ZC retourne un résultat
          },
        });
        xmlRequest.done(function (data) {
          const dom = new DOMParser().parseFromString(data, 'text/html');

          const recommendedFlag = dom.querySelectorAll(
            '.recommed-flag-container'
          );
          recommendedFlag &&
            Array.from(recommendedFlag).forEach((element) => {
              element.remove();
            });
          let titleVehicule = dom.querySelector('.results-search-again h1');

          if (titleVehicule instanceof HTMLElement && titleVehicule.innerText) {
            titleVehicule.innerText = titleVehicule.innerText.replace(
              'Chercheur de Batterie',
              ''
            );

            $('#battery-search-title').html(
              `<p class="mb-0">Modèle de véhicule recherché&nbsp;:</p><h6 class="mb-0">${titleVehicule.innerText}</h6>`
            );
          }

          $('#battery-search-body').html(dom.body);

          let getBlock = document.getElementsByClassName(
            'results-search-again'
          );
          getBlock[0].remove();
          var titleDOM = document.querySelectorAll('.product-header h3');
          for (let i = 0; i < titleDOM.length; i++) {
            let title = titleDOM[i].innerText;
            titleDOM[i].innerHTML = title;
            titleDOM[i].classList.add('text-success');
          }
          var imgDOM = document.querySelectorAll('.img');
          for (let i = 0; i < imgDOM.length; i++) {
            imgDOM[i].innerHTML = `
              <img src="${imgDOM[i].firstElementChild.lastElementChild.currentSrc}" alt="${imgDOM[i].firstElementChild.lastElementChild.alt}" class="img-fluid" />
            `;
          }
          var model = 'Model:';
          var cca = 'CCA:';
          var ukCode = 'UK Code:';
          var label = document.querySelectorAll('.label');
          var productFound = document.querySelectorAll('.product-found');

          for (let p = 0; p < productFound.length; p++) {
            productFound[p].addEventListener('click', function () {
              var inputSearch = /** @type {HTMLInputElement} */ (
                document.querySelector(
                  '#modalVartaResult .dgwt-wcas-search-input'
                )
              );

              const shortCodes = Array.from(
                this.querySelectorAll('.single-spec')
              ).filter((el) => {
                const label = el.querySelector('.label');
                return (
                  label &&
                  label instanceof HTMLElement &&
                  label.innerText.includes('Short Code:')
                );
              });

              if (
                shortCodes &&
                shortCodes.length > 0 &&
                shortCodes[0].querySelector('.description') !== null &&
                shortCodes[0].querySelector('.description').innerText
              ) {
                const sku =
                  shortCodes[0].querySelector('.description').innerText;
                inputSearch.value = sku;
                inputSearch.focus();
              }
            });
          }
          for (var i = 0; i < label.length; i++) {
            if (label[i].textContent == model) {
              let modelVal = label[i].nextSibling.nextElementSibling;
              modelVal.remove();
              label[i].remove();
            }
            if (label[i].textContent == cca) {
              let ccaVal = label[i].nextSibling.nextElementSibling;
              ccaVal.remove();
              label[i].remove();
            }
            if (label[i].textContent == ukCode) {
              let ukCodeVal = label[i].nextSibling.nextElementSibling;
              ukCodeVal.remove();
              label[i].remove();
            }
          }
        });
        xmlRequest.fail(function (jqXHR, textStatus) {
          console.error('Request failed: ' + textStatus); // eslint-disable-line
        }); // eslint
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
