/* eslint-disable */
export default {
  init() {
    const parentPos = document
      .querySelector('.component-map')
      .getBoundingClientRect().left;

    const childrenPos = document
      .querySelector('.map-list-product')
      .getBoundingClientRect().right;
    const leftOffset = childrenPos - parentPos;

    function initMap() {
      // Localisation du centre de la mpa
      var mapCenter = {
        lat: 43.695672,
        lng: 4.518007,
      };
      var icon = {
        url: siteData.templateUrl + '/assets/images/pointeur-jaune.png',
        size: new google.maps.Size(31, 50),
        anchor: new google.maps.Point(15, 50),
      };
      var mapElement = document.getElementById('product-map');
      /**
       * @type {google.maps.MapOptions}
       */
      var mapOptions = {
        disableDefaultUI: true,
        zoom: 8,
        center: mapCenter,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ],
      };

      function fitBounds(markers) {
        const bounds = new google.maps.LatLngBounds();
        Object.values(markers).forEach((marker) => {
          bounds.extend({
            lat: Number(marker.getPosition().lat()),
            lng: Number(marker.getPosition().lng()),
          });
        });

        // if there is a single marker, we expands the bounds to prevent zooming too far in
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new google.maps.LatLng(
            bounds.getNorthEast().lat() + 0.01,
            bounds.getNorthEast().lng() + 0.01
          );
          const extendPoint2 = new google.maps.LatLng(
            bounds.getNorthEast().lat() - 0.01,
            bounds.getNorthEast().lng() - 0.01
          );
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }

        map.fitBounds(bounds, {
          top: 0,
          bottom: 50,
          right: 50,
          left: leftOffset,
        });
      }
      function loadLocationInfo(loc) {
        return `
            <div class='map-info' style='width:auto; height:auto'> \
            <div class='container'> \
              <div class='row'> \
                <div class='col-md-6'> \
                  <div class='header mb-3'> \
                    <h3>${loc.nom_du_magasin}</h3> \
                  </div> \
                  <div class='body'> \
                    <span class='='>${loc.adresse}</span> \
                    <p class="mb-2 mt-3">Tel : <a href='tel:${
                      loc.numero_de_telephone
                    }'>${loc.numero_de_telephone}</a></p> \
                  </div> \
                  <div class="footer">\
                    <a href='/contact' class='btn btn-success mb-3 text-uppercase w-100' style='white-space:nowrap;'>Contacter ce magasin</a> \
                    <a href='${
                      loc.url_google_map_magasin
                    }' class='btn btn-success text-uppercase w-100' style='white-space:nowrap;' target='blank'>Itinéraire</a> \
                  </div>
                </div> \
                <div class='col-md-6'> \
                  <div class='body mb-3'> \
                    <table class='table'> \
                      <tbody> \
                      ${loc.horaires
                        .map((horaire) => {
                          return `
                                <tr> \
                                <th class="border-top-0">${
                                  horaire.day_of_the_week
                                }</th> \
                                <th class="border-top-0">
                                ${horaire.horaire_douverture} à
                                ${
                                  horaire.meal_break_schedule
                                    ? `${horaire.meal_break_schedule} <br> ${horaire.horaire_de_fin_de_pause_repas} à`
                                    : ''
                                }
                                ${horaire.horaire_de_fermeture}
                                </th> \
                                </tr> \
                                `;
                        })
                        .join('')}
                      </tbody> \
                    </table> \
                  </div> \
                </div> \
                </div> \
                </div> \
                </div>`;
      }

      // The map, centered at Uluru
      let map = new google.maps.Map(mapElement, mapOptions);
      var idShop = product_acf_store.stores;
      var idShopInformations = product_acf_store.informations;
      var markers = [];
      var infoWindows = [];
      for (var locationID in idShopInformations) {
        let infoWindow = new google.maps.InfoWindow();
        var loc = idShopInformations[locationID];
        infoWindow.setContent(loadLocationInfo(loc));
        infoWindows.push(infoWindow);
        let marker = new google.maps.Marker({
          position: {
            lat: parseFloat(loc.latitude_longitude.latitude),
            lng: parseFloat(loc.latitude_longitude.longitude),
          },
          map: map,
          icon: icon,
          title: loc.magasin_id,
          bounds: true,
        });

        marker.addListener('click', function () {
          toggleLocationMarker(locationID);
          map.setCenter({
            lat: parseFloat(loc.latitude_longitude.latitude),
            lng: parseFloat(loc.latitude_longitude.longitude),
          });
        });

        // const stockElt = document.getElementById(`click-stock_${loc.stock}`);

        markers[locationID] = marker;

        function toggleLocationMarker(location) {
          infoWindows.forEach((infoW) => infoW.close());
          infoWindow.open({
            map,
            anchor: markers[location],
          });
        }

        $(`[data-marker-location=${locationID}]`).on('click', function () {
          toggleLocationMarker($(this).data('marker-location'));
        });

        $(`[data-marker-location=${locationID}]`).on('mouseenter', function () {
          markers[$(this).data('marker-location')].setAnimation(
            google.maps.Animation.BOUNCE
          );
        });

        $(`[data-marker-location=${locationID}]`).on('mouseleave', function () {
          markers[$(this).data('marker-location')].setAnimation(null);
        });

        fitBounds(markers);
      }
    }
    let blockedMessage = /** @type {HTMLElement} */ (
      document.querySelector(
        '#product-map .cmplz-blocked-content-container div'
      )
    );
    if (blockedMessage) blockedMessage.style.marginLeft = `${leftOffset}px`;
    window.initMap = initMap;
  },
  finalize() {},
};
