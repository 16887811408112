import "isotope-layout/dist/isotope.pkgd.min";

export default {
    init() {
        jQuery(window).on(
            "load", function () {
                var $mainIso = $(".main-iso");

                $mainIso.isotope(
                    {
                        itemSelector: ".post",
                        layoutMode: "fitRows",
                    }
                );
                $(".nav-isotope").on(
                    "click", "button", function () {
                        var filterValue = $(this).attr("data-filter");
                        $mainIso.isotope({ filter: filterValue });
                    }
                );
            }
        );
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
